<template>
  <div class="privacy-policy">
    <div class="wrapper">
      <div class="privacy-policy__wrapper">
        <div class="privacy-policy__header">
          <h2 class="privacy-policy__title title">
            Privacy policy
          </h2>
        </div>
        <div class="privacy-policy__body">
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Introduction
            </h6>
            <p>
              This Privacy Policy of personal data (hereinafter referred to as
              the Privacy Policy) applies to all information that the Limited
              Liability Company "OtiumSoft" - the owner of the site, may receive
              about the User during the use of the site. The composition of the
              data to be collected, stored, processed and used is determined by
              this Privacy Policy.
            </p>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Definition of terms
            </h6>
            <p>
              The following terms are used in this Privacy Policy:
            </p>
            <ul>
              <li>
                <span>
                  "Site" – a web page used by the user in accordance with its
                  functionality.
                </span>
              </li>
              <li>
                <span>
                  "Administrator" – the site owner, as well as authorized site
                  management employees acting on behalf of the owner, who
                  directly organize and (or) process personal data, as well as
                  determine the purposes of personal data processing, the
                  composition of personal data to be processed, actions
                  (operations) performed with personal data.
                </span>
              </li>
              <li>
                <span>
                  "Personal data" - any information related directly or
                  indirectly to a specific or identifiable individual (subject
                  of personal data), legal entity (in the case of providing
                  employee data).
                </span>
              </li>
              <li>
                <span>
                  "Personal data processing" means any action (operation) or a
                  set of actions (operations) performed with or without the use
                  of automation tools with personal data, including the
                  collection, recording, systematization, accumulation, storage,
                  clarification (update, change), extraction, use, transfer
                  (distribution, provision, access), depersonalization,
                  blocking, deletion, destruction of personal data.
                </span>
              </li>
              <li>
                <span>
                  "Confidentiality of personal data" - a mandatory requirement
                  for the Administrator or other person who has access to
                  personal data to prevent their dissemination without the
                  consent of the subject of personal data or the presence of
                  other legal grounds.
                </span>
              </li>
              <li>
                <span>
                  "User" – a person who has access to the Site via the Internet
                  and directly uses it.
                </span>
              </li>
              <li>
                <span>
                  "Cookies" — a small piece of data sent by the web server and
                  stored on the user's device.
                </span>
              </li>
              <li>
                <span>
                  "IP address" — a unique network address of a node in a
                  computer network built using the IP protocol.
                </span>
              </li>
            </ul>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              General provisions
            </h6>
            <p>
              The User's use of the Site, acceptance of the consent to the use
              of Cookies means acceptance of this Privacy Policy and the terms
              of processing of the User's personal data.
            </p>
            <p>
              In case of disagreement with the terms of the Privacy Policy, the
              User must stop using the Site.
            </p>
            <p>
              This Privacy Policy applies only to this Site. The app owner is
              not responsible for third-party websites.
            </p>
            <p>
              The Administrator does not verify the accuracy of the personal
              data provided by the User of the Site.
            </p>
            <p>
              The Site may contain links to third-party resources that we do not
              manage, if you visit such a site, we strongly recommend that you
              read the Privacy Policy of each third-party resource you visit.
              The Administrator is not responsible for the content, privacy
              policy, or actions of any third-party sites and services.
            </p>
            <p>
              Personal data and other information of Users are not transferred
              to third parties without their consent, except for cases provided
              for by law, and information posted by Users on their own
              initiative in the public domain on the Site and its services.
            </p>
            <p>
              In the case of posting personal data and other information on the
              Site and its services on their own initiative in an open form,
              Users are aware that this information becomes available to third
              parties, taking into account the features of the architecture and
              functionality of the Site, and agree to this. In this case, the
              Site Administration is not responsible for the actions of
              third-parties. The Site Administration recommends that Users take
              a responsible approach to resolving the issue of the amount of
              personal information posted about themselves on the Site.
            </p>
            <p>
              The Site Administration takes technical and organizational and
              legal measures to ensure the protection of personal data and other
              information of Users from unauthorized or accidental access to
              them, destruction, modification, blocking, copying, distribution,
              as well as from other illegal actions. The exception is
              information that is independently posted by Users in the public
              domain on the Site and its services. In case of copying and
              distribution of such information by third parties, the Site
              Administration is not responsible.
            </p>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Subject of the privacy policy
            </h6>
            <p>
              This Privacy Policy sets out the obligations of the Administrator
              for non-disclosure and ensuring the confidentiality of personal
              data that the User provides at the request of the Administrator
              when registering and using the Site.
            </p>
            <p>
              The personal data allowed for processing under this Privacy Policy
              is provided by the User through the use of this website.
            </p>
            <p>
              The site automatically collects the necessary information for its
              operation:
            </p>
            <ul>
              <li>
                <span>
                  IP address;
                </span>
              </li>
              <li>
                <span>
                  information from cookies;
                </span>
              </li>
              <li>
                <span>
                  information about the browser (or other program that provides
                  access to advertising);
                </span>
              </li>
              <li>
                <span>
                  access time;
                </span>
              </li>
              <li>
                <span>
                  unique device identifiers;
                </span>
              </li>
              <li>
                <span>
                  other information obtained during the operation of the Site
                  and associated with it.
                </span>
              </li>
            </ul>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Purposes of collecting the user's personal information
            </h6>
            <p>
              The Administrator may use the User's personal data, including
              Cookies, for the following purposes:
            </p>
            <ul>
              <li>
                <span>
                  Creating a User account, with the User's consent.
                </span>
              </li>
              <li>
                <span>
                  Identification of the User registered on the Site.
                </span>
              </li>
              <li>
                <span>
                  Providing the User with access to personalized Site resources.
                </span>
              </li>
              <li>
                <span>
                  Establishing feedback with the User, including sending
                  notifications, requests related to the use of the Site, the
                  provision of services, processing requests and requests from
                  the User.
                </span>
              </li>
              <li>
                <span>
                  To notify the user of changes related to the Site.
                </span>
              </li>
              <li>
                <span>
                  Conducting an analysis, in the course of obtaining
                  information, in order to improve the functionality of the
                  Site.
                </span>
              </li>
              <li>
                <span>
                  To detect, prevent and solve technical problems.
                </span>
              </li>
              <li>
                <span>
                  Tracking the use of the Site.
                </span>
              </li>
            </ul>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Methods and terms of processing of personal information. Transfer
              of personal data. Storage of personal data
            </h6>
            <p>
              The processing of the User's personal data is carried out without
              a time limit, in any legal way, including in the information
              systems of personal data with or without the use of automation
              tools, except for the cases of the maximum possible period of
              storage of personal data.
            </p>
            <p>
              Personal data may be stored on the storage of a third-party
              resource located outside the territory of the Administrator.
            </p>
            <p>
              All personal data and information obtained during the operation of
              the Site is stored and used in accordance with the legislation.
            </p>
            <p>
              The Administrator, with the consent of the User, has the right to
              transfer personal data to third parties, if such services are
              provided to the Administrator related to the User's tasks. Third
              parties may obtain information only for the performance of
              assigned tasks and undertake not to disclose them, and not to use
              them for third-party tasks.
            </p>
            <p>
              The User's personal data may be transferred to the authorized
              state authorities only on the grounds and in accordance with the
              procedure established by law.
            </p>
            <p>
              In case of loss or disclosure of personal data, the Administrator
              informs the User about the loss or disclosure of personal data.
            </p>
            <p>
              The Administrator takes the necessary organizational and technical
              measures to protect the User's personal information from
              unauthorized or accidental access, destruction, modification,
              blocking, copying, distribution, as well as from other illegal
              actions of third parties.
            </p>
            <p>
              In the case of the use of cookies, in accordance with the GDPR,
              the Administration hereby warns about the provision of part of the
              files to third parties, in accordance with the technical means
              used, analytical algorithms, programs.
            </p>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Obligations of the parties
            </h6>
            <p>
              The User is obliged to:
            </p>
            <ul>
              <li>
                <span>
                  Provide information about personal data required for the use
                  of the Site.
                </span>
              </li>
              <li>
                <span>
                  Update, supplement the provided information about personal
                  data in case of changes in this information.
                </span>
              </li>
            </ul>
            <p>
              The Administrator must:
            </p>
            <ul>
              <li>
                <span>
                  Use the received information exclusively for the purposes
                  specified in article 4 of this Privacy Policy.
                </span>
              </li>
              <li>
                <span>
                  To ensure that confidential information is kept secret, not to
                  disclose without the prior written permission of the User, as
                  well as not to sell, exchange, publish, or otherwise disclose
                  the User's personal data, except for the requirements provided
                  for by law.
                </span>
              </li>
              <li>
                <span>
                  Take precautions to protect the confidentiality of the User's
                  personal data in accordance with the procedure usually used to
                  protect this type of information in the existing business
                  turnover.
                </span>
              </li>
              <li>
                <span>
                  To block the personal data related to the relevant User from
                  the moment of the request of the User or his legal
                  representative or the authorized body for the protection of
                  the rights of personal data subjects for the period of
                  verification, in case of identification of unreliable personal
                  data or illegal actions.
                </span>
              </li>
            </ul>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Liability of the parties
            </h6>
            <p>
              The Administrator, who has not fulfilled his obligations, is
              responsible for the losses incurred by the User in connection with
              the illegal use of personal data, in accordance with the law.
            </p>
            <p>
              In case of loss or disclosure of Confidential Information, the
              Administrator is not responsible if this confidential information:
            </p>
            <ul>
              <li>
                <span>
                  Became public domain before its loss or disclosure.
                </span>
              </li>
              <li>
                <span>
                  It was received from a third party before it was received by
                  the Administrator.
                </span>
              </li>
              <li>
                <span>
                  Was disclosed with the User's consent.
                </span>
              </li>
            </ul>
          </div>
          <div class="privacy-policy__block">
            <h6 class="privacy-policy__subtitle">
              <div class="vertical-line"></div>
              Additional terms and conditions
            </h6>
            <p>
              The Administrator has the right to make changes to this Privacy
              Policy without the User's consent.
            </p>
            <p>
              The new Privacy Policy comes into force from the moment it is
              posted on the Site, unless otherwise provided by the new version
              of the Privacy Policy.
            </p>
            <p>
              All suggestions or questions regarding this Privacy Policy should
              be sent to the Administrator's email address:
              <a href="mailto:info@otiumsoft.com" class="cookie__email"
                >info@otiumsoft.com</a
              >
            </p>
            <p>
              The current Privacy Policy is comprehensive and includes a Cookie
              Policy.
            </p>
          </div>
        </div>
      </div>
      <div class="cookie__wrapper">
        <div class="cookie__header">
          <h2 class="cookie__title title">
            Cookie Policy
          </h2>
        </div>
        <div class="cookie__body">
          <div class="cookie__block">
            <h6 class="cookie__subtitle">
              <div class="vertical-line"></div>
              Introduction
            </h6>
            <p>
              Limited liability company «OtiumSoft» (hereinafter referred to as
              the "Administration") has undertaken to provide you with
              information about the cookies we use.
            </p>
            <p>
              One of the priorities of the Administration is to protect your
              personal information, including personal data, and we strive to
              ensure transparency in the process of using such information.
            </p>
            <p>
              General information about what cookies are, which does not fully
              reflect all possible types and types of cookies, their attributes,
              installation algorithms, expiration conditions, and methods of
              deletion are presented below.
            </p>
            <p>
              This document describes the data collected about the users of the
              Internet site https://www.otiumsoft.com (hereinafter referred to
              as the Site) when the Administration uses cookies.
            </p>
            <p>
              A cookie is a small text file containing your data that is saved
              every time you visit a website or app. These cookies help websites
              and applications remember your previous actions, even if they were
              made by you several months or several years ago. These actions may
              include clicking on certain links or moving between pages, logging
              in, or selecting certain information on the page.
            </p>
            <p>
              There are different types of cookies, without which Internet
              sites, applications and other digital services could not function
              in the usual way for you.
            </p>
            <p>
              For example, if you confirm your login without a cookie, the
              website would not be able to display your personal page, or every
              time you visit the website, the message "register to log in" would
              appear on the screen, since the website could not remember your
              credentials.
            </p>
            <p>
              You can always delete existing cookies or even completely block
              their use on Internet sites.
            </p>
          </div>
          <div class="cookie__block">
            <h6 class="cookie__subtitle">
              <div class="vertical-line"></div>
              Types of cookies:
            </h6>
            <ul>
              <li>
                <span>
                  <b>
                    Strictly necessary Cookies.
                  </b>
                  <p>
                    There are cookies, without which it would be impossible to
                    navigate through Internet sites, as well as use their
                    functional characteristics, such as access to safe zones.
                    Without such files, the user cannot register or log in.
                    These cookies do not store any information about you that
                    can be used for marketing purposes or to track the sites you
                    visit on the Internet.
                  </p>
                </span>
              </li>
              <li>
                <span>
                  <b>
                    Analytical Cookies.
                  </b>
                  <p>
                    These cookies are used to collect information about how
                    users use the Site, such as which pages they visit most
                    often and whether they receive error messages. They are not
                    used to collect personally identifiable information; all
                    information is anonymous. It is used exclusively to improve
                    the functionality of the site or application, as well as the
                    use of third parties that provide analytics tools for
                    cookies.
                  </p>
                </span>
              </li>
              <li>
                <span>
                  <b>
                    Functional Cookies.
                  </b>
                  <p>
                    Such files are used to collect data about user settings (for
                    example, the user's name, the user's chosen language or
                    region), on the basis of which the website offers the user
                    improved personal features. Functional cookies can store
                    information about changes you have made to the text size or
                    font type, as well as other settings available to users.
                    They may also provide information about the Internet
                    resources you use, such as, for example, watching a video
                    clip or saving a comment on a blog. If necessary, the
                    information collected by these files can be made anonymous.
                    They also track other sites and apps that you visit.
                  </p>
                </span>
              </li>
              <li>
                <span>
                  <b>
                    Target Cookies.
                  </b>
                  <p>
                    These files allow you to display ads or messages on a topic
                    that interests you. Sometimes the target cookies may contain
                    links to other sites, the sites of advertisers.
                  </p>
                </span>
              </li>
            </ul>
            <p>
              The types of cookies described above may be "temporary" (session
              cookies) or" long-term "(persistent cookies).
            </p>
            <ul>
              <li>
                <span>
                  <b>
                    "Session"
                  </b>
                  cookies are automatically deleted after the end of the visit
                  to the website.
                </span>
              </li>
              <li>
                <span>
                  <b>
                    "Persistent"
                  </b>
                  cookies remain on your device for a certain period of time.
                  They are activated automatically when you visit a website or
                  application, except as defined in this Cookie Policy.
                </span>
              </li>
            </ul>
          </div>
          <div class="cookie__block">
            <h6 class="cookie__subtitle">
              <div class="vertical-line"></div>
              Use of cookies by the Administration when visiting the Site
            </h6>
            <p>
              The cookies used by the Administration when visiting the Site
              contain both technical information (such information includes the
              type of your Internet browser, the type of operating system of
              your computer and the domain name of the Internet site that you
              used to access the Site), also analytical and statistical
              information. The Site administration reserves the right to
              supplement the list of collected statistical information by means
              of cookies.
            </p>
            <p>
              List of cookies used by the administration:
            </p>
            <p>
              Google analytics – the Google service used by us to collect
              statistical information, a list of possible cookies can be found
              on
              <a
                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=eng"
                target="_blank"
                >the official website of the developer.</a
              >
            </p>
          </div>
          <div class="cookie__block">
            <h6 class="cookie__subtitle">
              <div class="vertical-line"></div>
              Deleting cookies, refusing to use cookies.
            </h6>
            <p>
              If you want to opt out of the use of cookies when visiting the
              Site, you can delete Cookies from the cookie folder in the browser
              you are using. Change the settings of the browser you are using to
              block cookies or to trigger a warning message before any cookies
              are stored on your computer, if the browser you are using has such
              functionality. You have the right to send an e-mail marked “cookie
              " with a request to delete the cookies stored by us prematurely.
            </p>
            <p>
              Please note that some parts (modules) of the site will not be
              available for your visit (use) if "cookies" are disabled, are not
              subject to collection.
            </p>
            <p>
              The administration cannot provide comprehensive instructions on
              how to manage cookies for all types of existing browsers and
              operating systems, but it can provide links to the official
              resources of the developers of the most popular browsers: 
              <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en" target="_blank">Google</a>, 
              <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac" target="_blank">Safari</a>.
            </p>
          </div>
          <div class="cookie__block">
            <h6 class="cookie__subtitle">
              <div class="vertical-line"></div>
              Contact Information
            </h6>
            <p>
              If you have any questions regarding this Privacy Policy, the
              Cookie Policy, you can contact us in the following way:
            </p>
            <p>
              <b>OtiumSoft LLC</b>
            </p>
            <p>
              <b>Zakharova 29A str., room 77e,</b>
            </p>
            <p>
              <b>
                Minsk 220034, Belarus
              </b>
            </p>
            <p>
              <b>
                <a href="mailto:info@otiumsoft.com" class="cookie__email"
                  >info@otiumsoft.com</a
                >
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
};
</script>

<style lang="scss" scoped>
.wrapper {
  flex-direction: column;
}
.cookie__wrapper {
  padding-top: 50px;
  @include for-tablet-portrait-up {
    padding-top: 70px;
  }
  @include for-desktop-up {
    padding-top: 60px;
  }
}
.privacy-policy,
.cookie {
  padding: 110px 10px 50px 10px;
  width: 100%;
  @include for-tablet-portrait-up {
    padding: 132px 15px 60px 15px;
  }
  @include for-tablet-landscape-up {
    padding: 142px 16px 70px 16px;
  }
  @include for-desktop-up {
    padding: 132px 0 106px 0;
  }
  &__title {
    @include H4-semi-bold();
    padding-bottom: 40px;
    color: var(--dark-blue-color);
    @include for-tablet-landscape-up {
      @include H2-semi-bold();
    }
    @include for-desktop-up {
      @include H1-semi-bold();
      padding-bottom: 60px;
    }
  }
  &__subtitle {
    margin-bottom: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include P1-bold();
    @include for-tablet-portrait-up {
      @include P1-bold();
      margin-bottom: 16px;
    }
    @include for-tablet-landscape-up {
      @include H5-bold();
      margin-bottom: 11px;
    }
    @include for-desktop-up {
      @include H5-bold();
    }
    .vertical-line {
      background: var(--dark-gradient-background);
      width: 4px;
      height: 30px;
      border-radius: 8px;
      margin-right: 8px;
      @include for-tablet-portrait-up {
        height: 34px;
        margin-right: 10px;
      }
      @include for-tablet-landscape-up {
        height: 42px;
      }
    }
  }
  &__block + &__block {
    margin-top: 30px;
  }
  &__block {
    p {
      @include P4();
      @include for-tablet-landscape-up {
        @include P3();
      }
    }
    p + p {
      margin-top: 8px;
      @include for-tablet-portrait-up {
        margin-top: 10px;
      }
    }
    ul {
      list-style-type: disc !important;
      list-style-position: outside !important;
      color: var(--dark-blue-color);
      padding: 0 0 0 17px !important;
      @include P4();
      @include for-tablet-landscape-up {
        @include P3();
      }
      li + li {
        margin-top: 8px;
        @include for-tablet-portrait-up {
          margin-top: 10px;
        }
      }
      li {
        span {
          color: var(--black-color);
        }
      }
    }
  }
  &__email {
    color: var(--dark-blue-color);
    font-weight: 600;
  }
  a{
    color: var(--black-color);
    &:hover{
      cursor: pointer;
    }
  }
}
.privacy-policy__wrapper {
  display: flex;
  flex-direction: column;
}
</style>
